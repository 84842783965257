<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/auth-local" text=""></ion-back-button>
        </ion-buttons>
        <ion-title>
          <ion-img
            src="assets/logo-header-toolbar.svg"
            class="img-toolbar"
          ></ion-img>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding" fullscreen="true">
      <form @submit.prevent="submit" class="max-width-mobile">
        <h2 style="font-weight: 500">{{ $t("Create new account") }}</h2>

        <div>
          <ion-item>
            <ion-label position="floating">{{ $t("User type") }}</ion-label>
            <ion-select
              v-model="user.user_type"
              name="user_type"
              :ok-text="$t('Accept')"
              :cancel-text="$t('Cancel')"
            >
              <ion-select-option value="cuidador">{{
                $t("Caregiver")
              }}</ion-select-option>
              <ion-select-option value="beneficiario">{{
                $t("Dependant")
              }}</ion-select-option>
            </ion-select>
          </ion-item>
          <template v-if="v$.user_type.$error">
            <p
              v-for="(error, i) of v$.user_type.$errors"
              :key="i"
              class="error"
            >
              {{ $t(error.$message) }}
            </p>
          </template>
        </div>

        <div>
          <ion-item>
            <ion-label position="floating">{{ $t("Name") }}</ion-label>
            <ion-input
              v-model="user.name"
              name="name"
              type="text"
              inputmode="text"
              @change="v$.name.$touch()"
            ></ion-input>
          </ion-item>
          <template v-if="v$.name.$error">
            <p v-for="(error, i) of v$.name.$errors" :key="i" class="error">
              {{ $t(error.$message) }}
            </p>
          </template>
        </div>

        <div>
          <ion-item>
            <ion-label position="floating">{{ $t("Surname") }}</ion-label>
            <ion-input
              v-model="user.surname"
              name="surname"
              type="text"
              inputmode="text"
              @change="v$.surname.$touch()"
            ></ion-input>
          </ion-item>
          <template v-if="v$.surname.$error">
            <p v-for="(error, i) of v$.surname.$errors" :key="i" class="error">
              {{ $t(error.$message) }}
            </p>
          </template>
        </div>

        <div>
          <ion-item>
            <ion-label position="floating">{{ $t("E-mail") }}</ion-label>
            <ion-input
              v-model="user.email"
              name="email"
              type="email"
              inputmode="email"
              @change="v$.email.$touch()"
            ></ion-input>
          </ion-item>
          <template v-if="v$.email.$error">
            <p v-for="(error, i) of v$.email.$errors" :key="i" class="error">
              {{ $t(error.$message) }}
            </p>
          </template>
        </div>

        <div>
          <ion-item>
            <ion-label position="floating">{{ $t("Repeat e-mail") }}</ion-label>
            <ion-input
              v-model="user.repeat_email"
              name="repeat_email"
              type="email"
              inputmode="email"
              @change="v$.repeat_email.$touch()"
            ></ion-input>
          </ion-item>
          <template v-if="v$.repeat_email.$error">
            <p
              v-for="(error, i) of v$.repeat_email.$errors"
              :key="i"
              class="error"
            >
              {{ $t(error.$message) }}
            </p>
          </template>
        </div>

        <div>
          <ion-item>
            <ion-label position="floating">{{ $t("Password") }}</ion-label>
            <ion-input
              v-model="user.password"
              name="password"
              :type="showPassword.password ? 'text' : 'password'"
              class="password"
              @change="v$.password.$touch()"
            ></ion-input>
            <ion-icon
              slot="end"
              @click="switchShowPassword('password')"
              :icon="showPassword.password ? eyeOff : eye"
            ></ion-icon>
          </ion-item>
          <template v-if="v$.password.$error">
            <p v-for="(error, i) of v$.password.$errors" :key="i" class="error">
              {{ $t(error.$message) }}
            </p>
          </template>
        </div>

        <div>
          <ion-item>
            <ion-label position="floating">{{
              $t("Repeat password")
            }}</ion-label>
            <ion-input
              v-model="user.repeat_password"
              name="repeat_password"
              :type="showPassword.repeatPassword ? 'text' : 'password'"
              class="password"
              @change="v$.repeat_password.$touch()"
            ></ion-input>
            <ion-icon
              slot="end"
              @click="switchShowPassword('repeatPassword')"
              :icon="showPassword.repeatPassword ? eyeOff : eye"
            ></ion-icon>
          </ion-item>
          <template v-if="v$.repeat_password.$error">
            <p
              v-for="(error, i) of v$.repeat_password.$errors"
              :key="i"
              class="error"
            >
              {{ $t(error.$message) }}
            </p>
          </template>
        </div>

        <ion-button
          type="submit"
          expand="block"
          size="medium"
          class="ion-margin-top"
          >{{ $t("Register") }}</ion-button
        >

        <div class="acceptance">
          <ion-item lines="none">
            <ion-checkbox
              slot="start"
              v-model="user.acceptance"
              name="acceptance"
              @change="v$.acceptance.$touch()"
            ></ion-checkbox>
            <ion-label>
              Acepto las
              <a
                @click.prevent="
                  openInBrowser(
                    'https://casermasbeneficios.es/condiciones-servicios'
                  )
                "
                >Condiciones Generales</a
              >
              y la
              <a
                @click.prevent="
                  openInBrowser(
                    'https://casermasbeneficios.es/politica-privacidad'
                  )
                "
                >Política de Privacidad</a
              >, incluyendo el
              <a
                @click.prevent="
                  openInBrowser('https://casermasbeneficios.es/uso-cookies')
                "
                >libro de Cookies</a
              >.
            </ion-label>
          </ion-item>
          <template v-if="v$.acceptance.$error">
            <p
              v-for="(error, i) of v$.acceptance.$errors"
              :key="i"
              class="error"
            >
              {{ $t(error.$message) }}
            </p>
          </template>
        </div>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonBackButton,
  IonImg,
  IonLabel,
  IonIcon,
  IonItem,
  IonInput,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
  alertController,
  loadingController
} from "@ionic/vue";
import { eye, eyeOff } from "ionicons/icons";
import { reactive, readonly, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  sameAs,
  minLength,
  helpers
} from "@vuelidate/validators";
import { InAppBrowser } from "@ionic-native/in-app-browser";

export default {
  name: "Register",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonBackButton,
    IonImg,
    IonLabel,
    IonIcon,
    IonItem,
    IonInput,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonCheckbox
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();

    const showPassword = reactive({
      password: false,
      repeatPassword: false
    });

    const switchShowPassword = name =>
      (showPassword[name] = !showPassword[name]);

    const user = reactive({
      user_type: null,
      name: null,
      surname: null,
      email: null,
      repeat_email: null,
      password: null,
      repeat_password: null,
      acceptance: false,
      device_name: "app"
    });

    const accepted = value => {
      console.log(value);
      return value ? true : false;
    };

    const rules = computed(() => ({
      user_type: { required },
      name: { required },
      surname: { required },
      email: { required, email },
      repeat_email: { sameAs: sameAs(user.email) },
      password: { required, minLength: minLength(8) },
      repeat_password: { sameAsPassword: sameAs(user.password) },
      acceptance: {
        accepted: helpers.withMessage(
          t("Terms and conditions must be accepted"),
          accepted
        )
      }
    }));

    const v$ = useVuelidate(rules, user);

    const submit = async () => {
      if (v$._value.$invalid) {
        v$._value.$touch();
        const alert = await alertController.create({
          cssClass: "error-alert",
          header: t("Error"),
          message: t("Please check the data required"),
          buttons: [t("Ok")]
        });
        alert.present();
        return false;
      }
      const data = readonly(user);
      const sending = await loadingController.create({
        message: t("Sending...")
      });
      await sending.present();

      await store.dispatch("auth/register", data);
      sending.dismiss();

      if (store.state.auth.error) {
        const alert = await alertController.create({
          cssClass: "error-alert",
          header: t("Error"),
          message: store.state.auth.error.join("<br>"),
          buttons: [t("Ok")]
        });
        await alert.present();
        return;
      }

      if (store.state.auth.user) {
        router.push({ name: "Diary", replace: true });
      }
    };

    const openInBrowser = url => {
      InAppBrowser.create(url, "_blank", {
        hideurlbar: "no",
        hidenavigationbuttons: "yes",
        hardwareback: "yes",
        location: "yes",
        presentationstyle: "fullscreen",
        footer: "yes",
        toolbar: "yes",
        toolbarcolor: "#FFFFFF",
        toolbarposition: "top",
        toolbartranslucent: "no",
        closebuttoncaption: "Cerrar"
      });
    };

    return {
      showPassword,
      switchShowPassword,
      eye,
      eyeOff,
      user,
      v$,
      submit,
      openInBrowser
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme/custom-variables";

.password {
  & + ion-icon {
    display: none;
  }
  &.has-focus + ion-icon,
  &.has-value + ion-icon {
    display: block;
  }
  & + ion-icon {
    align-self: flex-end;
  }
}

.acceptance {
  ion-label {
    font-size: 0.8em;
    height: auto;
    white-space: normal;
  }
}
</style>
